import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-in-box',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class AudioInBoxComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'AudioInBoxDetailContentComponent';
    this.detailButtonBarComponentClassName = 'AudioInBoxDetailButtonBarComponent';
    super.ngOnInit();
  }

}
