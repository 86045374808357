<span *ngIf="f">
    <span *ngIf="mode === 'read'">
        ********
    </span>
    <span *ngIf="mode === 'write'">
      <div class="form-group marginBottom0">
        <div class="input-group" [title]="(f.required ? '* ' : '') + (title ? title : f.name)">
          <input 
            class="form-control"
            [type]="(passwordIsHidden ? 'password' : 'text')" 
            [placeholder]="(f.required ? '* ' : '') + f.name"
            [name]="f.name"
            [title]="(title ? title : f.name)"
            [size]="(f.length && (f.length < size) ? f.length : size)"
            [value]="f.value" 
            [pattern]="f.pattern"
            [maxLength]="f.length"
            [disabled]="f.readOnly"
            (input)="onInputChange($event)"
            (blur)="forceValidValue($event)"
          />
          <div class="input-group-append">
            <button
                title="toggle password visibility"
                class="btn btn-outline-secondary paddingTop4"
                (click)="togglePasswordMask($event)"
                type="button"
                [disabled]="f.readOnly"
            >
          <span class="top smallish fas fa-eye{{(passwordIsHidden ? '' : '-slash')}}"></span>
            </button>
          </div>
        </div>
      </div>
      <div class="small italic">
        Passwords must be at least {{ f.minLength }} characters in length,
        and include upper case, lower case, numeric and special characters.
      </div>
    </span>
</span>
