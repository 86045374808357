<div class="" [ngClass]="{ 'paddingWholeEm d-flex justify-content-around align-items-center' : (screenType != 'phone') }" *ngIf="resource">
  <div class="card">
    <div class="card-header" [ngClass]="{ 'd-flex justify-content-between align-items-center' : (screenType !== 'phone') }">
      <div>
        <wackadoo-field [f]="resource.artistName" [mode]="mode"></wackadoo-field>
      </div>
      <div>
        <wackadoo-field [f]="resource.projectName" [mode]="mode"></wackadoo-field>
      </div>
      <div>
        <wackadoo-field [f]="resource.projectType" [mode]="mode"></wackadoo-field>
      </div>
      <div>
        <wackadoo-field [f]="resource.clientID" [mode]="mode"></wackadoo-field>
      </div>
    </div>
    <div class="card-body">
      <table>
        <tr>
          <td class="bold smallish top">
            DownloadLink
          </td>
          <td>
            <wackadoo-field [f]="resource.downloadLink" [mode]="mode"></wackadoo-field>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Due Date
          </td>
          <td>
            <wackadoo-field [f]="resource.dueDate" [mode]="mode"></wackadoo-field>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Verbal Go Ahead Date
          </td>
          <td>
            <wackadoo-field [f]="resource.verbalGoAheadDate" [mode]="mode"></wackadoo-field>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Invoice Date
          </td>
          <td>
            <wackadoo-field [f]="resource.invoiceDate" [mode]="mode"></wackadoo-field>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Payment Date
          </td>
          <td>
            <wackadoo-field [f]="resource.paymentDate" [mode]="mode"></wackadoo-field>
          </td>
        </tr>
<!--
        <tr>
          <td class="bold smallish top">
            Download Date
          </td>
          <td>
            <wackadoo-field [f]="resource.downloadDate" [mode]="mode"></wackadoo-field>
          </td>
        </tr>
-->
        <tr>
          <td class="bold smallish top">
            Project Note
          </td>
          <td>
            <div class="fullWidth">
              <wackadoo-field-string [f]="resource.projectNote" [mode]="mode" [forceWrap]="true"></wackadoo-field-string>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <div>
        Negotiated Prices
      </div>
      <div class="small italics">
        (Note: Project-level negotiated prices take precedence over Client-level negotiated prices, if both are present.)
      </div>
    </div>
    <div class="card-body">
      <table>
        <tr>
          <td class="bold smallish top">
            Mix
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.projectNegotiatedPriceMix" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Mix & Vocal Editing
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.projectNegotiatedPriceMixAndVocalEdit" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Mix & Master
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.projectNegotiatedPriceMixAndMaster" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            The Package
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.projectNegotiatedPricePackage" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
