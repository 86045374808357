<div class="card" *ngIf="resource" [ngClass]="{ 'maxWidthViewPort30' : (screenType === 'fullscreen')  }">
  <div class="card-header">
    {{customerInfoTitle}}
  </div>
  <div class="card-body">
    <div class="" [ngClass]="{ 'd-flex justify-content-between' : (screenType === 'fullscreen') && !userInterfaceService.isSmallFullScreen() }">
      <div class="marginBottomHalfEm">
        <table>
          <tr>
            <td class="bold smallish top">
              Name
            </td>
            <td>
              <div class="" [ngClass]="{ 'd-flex flex-row' : (screenType === 'fullscreen') }">
                <div>
                  <wackadoo-field [f]="resource.firstName" [mode]="mode" [size]="12" [onChange]="customerChangedSubject"></wackadoo-field>
                </div>
                <div class="" [ngClass]="{ 'marginLeftHalfEm' : (screenType === 'fullscreen') }">
                  <wackadoo-field [f]="resource.lastName" [mode]="mode" [size]="12" [onChange]="customerChangedSubject"></wackadoo-field>
                </div>
              </div>
              <div>
                <wackadoo-field [f]="resource.company" [mode]="mode" [size]="32" [onChange]="customerChangedSubject"></wackadoo-field>
              </div>
            </td>
          </tr>
          <tr>
            <td class="bold smallish top">
              Address
            </td>
            <td>
              <div>
                <wackadoo-field [f]="resource.address" [mode]="mode" [size]="32" [onChange]="customerChangedSubject"></wackadoo-field>
              </div>
              <div>
                <wackadoo-field [f]="resource.city" [mode]="mode" [size]="32" [onChange]="customerChangedSubject"></wackadoo-field>
              </div>
              <div class="d-flex flex-row">
                <div>
                  <wackadoo-field [f]="resource.state" [mode]="mode" [size]="2" [onChange]="customerChangedSubject"></wackadoo-field>
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.postalCode" title="zipcode" [mode]="mode" [size]="5" [onChange]="customerChangedSubject"></wackadoo-field>
                </div>
                <div class="marginLeftHalfEm">
                  <wackadoo-field [f]="resource.country" [mode]="mode" [size]="2" [onChange]="customerChangedSubject"></wackadoo-field>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td class="bold smallish top">
                Contact Info
            </td>
            <td>
              <div>
                <wackadoo-field [f]="resource.email" [mode]="mode" [size]="32" [onChange]="customerChangedSubject"></wackadoo-field>
              </div>
              <div>
                <wackadoo-field [f]="resource.phone" [mode]="mode" [onChange]="customerChangedSubject"></wackadoo-field>
              </div>
            </td>
          </tr>
          <tr *ngIf="mode === 'write'">
            <td class="bold smallish top">
            </td>
            <td class="small italic">
              * indicates required field
              <div class="marginTopHalfEm" *ngIf="additionalMessage">{{additionalMessage}}</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
