import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/client-core/services/global.service';
import { FooterComponent } from 'src/app/client-core/ui/footer/footer.component';

@Component({
  selector: 'wackadoo-audio-footer',
  templateUrl: './audio-footer.component.html',
})
export class AudioFooterComponent extends FooterComponent implements OnInit {

  domain: string;

  ngOnInit(): void {
    this.domain = Globals.domain;
    super.ngOnInit();
  }

}
