import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-projects',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class ProjectsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'ProjectDetailContentComponent';
    this.detailButtonBarComponentClassName = 'ProjectDetailButtonBarComponent';
    super.ngOnInit();
  }

}
