import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';

@Component({
  selector: 'wackadoo-audio-process',
  templateUrl: './audio-process.component.html',
})
export class AudioProcessComponent extends PageComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
