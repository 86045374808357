<div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Getting started...
        </div>
        <div class="card-body">
          <p>
            Simply reach out via one of the Contact email links (or if you want, go to the InBox page and submit the full details of your project)
            and we'll set up a call or video meeting to discuss your project.
          </p>
          <p>
            If I think I can help you, and you think I'm a good fit for your music, then we go over the project details and schedule the work.
          </p>
          <p>
            When I have something ready for you to review, I will send you the link to an MP3 file for your comments & input.
          </p>
          <p>
            Once you like what you hear, I will send you an email with a link to the Invoice/ShoppingCart page where you will be able to 
            pay me for services rendered. <i>(If this is the first time we have worked together, your first song will be at no charge.)</i>
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_left">
        <img src="./assets/app-images/upright bridge (8).jpg" alt="bass amplifier">
        <div class="italic">
          Sometimes direct, sometimes through a pre-amp...
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_right marginTop4em">
        <img src="./assets/app-images/cubase (1).jpg" alt="DAW Screenshot">
        <div class="italic">
          Clearly a work in progress...
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Once payment is received...
        </div>
        <div class="card-body">
          <div class="marginBottomWholeEm">
            ...click on the link to your secure download page, where you will need the password you created 
            to download the WAV and hi-def MP3 files.  You will also receive two automated emails:
            <ol>
              <li>
                Your receipt from <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span>, who we use to handle online payments.
              </li>
              <li>
                Confirmation from <span class="bold italic green">{{domain}}</span>, including a copy of your download link in case we need to make further revisions.
              </li>
            </ol>
          </div>
          <p>
            If you have any questions, feel free to get in touch. I'd love to talk over your project with you - whether you use my services or not.
          </p>
          <p>
            But if you're ready to go, just click on <span class="link" wackadooPageLoad pageName="InBox">InBox</span> to submit a project.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
