import { Component, OnInit } from '@angular/core';
import { DetailButtonBarComponent } from 'src/app/client-core/pages/repository-page/detail-button-bar/detail-button-bar.component';
import { WEvent } from 'src/app/client-core/data/event.model';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-audio-in-box-detail-button-bar',
  templateUrl: './audio-in-box-detail-button-bar.component.html',
})
export class AudioInBoxDetailButtonBarComponent extends DetailButtonBarComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'AudioInBoxDetailButtonBarComponent';

  // something hard-coded that we can use for debug purposes...
  debug = false;


  ngOnInit(): void {
    super.ngOnInit();
  }

  // A cut-down version of regular save() in add mode, but with a modal dialog msg at the end for the public user...

  submit(): void {

    // console.log(this.resource, this.resource.asParms, this.resource.getChangedFieldValuesAsParms(false));

    const badField = this.resource.identifyMissingOrInvalidField(true);
    if (badField) {
      this.modalDialogService.showAlert('Invalid field value or missing required field: ' + badField.name, 'Error');
    } else {

      const parms: any = this.resource.getChangedFieldValuesAsParms(false);

      if (this.debug) {
        console.log('submit()', this.eventHandler, 'add', this.resource, this.resource.asParms, parms);
      }

      // do the save here, then notify the user, and
      // (for public users...) load the home page,
      // but if I'm logged in, sit on the InBox page.

      this.modalDialogService.showPleaseWait(true);

      this.eventServerService.fireEvent(this.eventHandler, 'add', parms).subscribe(
          (event: WEvent) => {

            this.modalDialogService.showPleaseWait(false);

            if (event.status === 'OK') {

              const title = 'Thank You ' + this.resource.clientFirstName.value + '!';

              let msg = '<div class="marginTopWholeEm">Your project <i>' + parms.projectName + '</i> is now in my InBox at <b><i>' + Globals.domain + '</i></b>, and I have been sent an email notification.</div>'
                          + '<div>After I get a chance to take a look at it, I\'ll set up an online meeting with you so we can discuss your project in person.</div>';

              if (!this.openForBusiness) {
                  msg += '<div class="italics marginTopWholeEm">(Given what time it is locally, I probably won\'t see that until morning...)</div>';
              }

              msg += '<div class="marginTopWholeEm">Thanks again,</div>';
              msg += '<div class="">Steve</div>';

              this.modalDialogService.showAlert(msg, title).subscribe(
                () => {
                  this.userInterfaceService.loadPage('home');
                }
              );

            } else {
              const title = 'My apologies...';

              const msg = '<div>There was an error submitting your project.</div>'
                      + '<div>Unfortunately, you\'re going to have to re-do that.</div>'
              ;

              this.modalDialogService.showAlert(msg, title).subscribe(
                () => {
                  this.userInterfaceService.loadPage('home');
                }
              );
            }
          }
        );
    }
  }


  approve(): void {

    if (this.debug) {
      console.log('AudioInBox.approve()', this.resource);
    }

    try {

      this.resource.markAllFieldsAsChanged();

      // We need the inputFormID field because AudioCustom.approveProject()
      // uses it to delete the InputForm if everything goes OK.
      const parms: any = this.resource.getChangedFieldValuesAsParms(true);

      delete parms.created;
      delete parms.modified;
      delete parms.agent;

      if (this.debug) {
        console.log('calling AudioCustomer.approveProject() w/parms:', parms);
      }

      this.modalDialogService.showPleaseWait('Generating New Client, Project and Songs...');

      this.eventServerService.fireEvent('AudioCustomer', 'approveProject', parms).subscribe(
        (tempEvent: WEvent) => {
          try {
            this.modalDialogService.showPleaseWait(false);

            if (this.debug) {
              console.log('AudioInBox.approve()', tempEvent);
            }

            if (tempEvent.status === 'OK') {

              this.userInterfaceService.unSelectResource('InBox');
              this.userInterfaceService.unSelectResource('Clients');
              this.userInterfaceService.unSelectResource('Projects');
              this.userInterfaceService.unSelectResource('Songs');

              const parms2: any = {};
              parms2.projectID = tempEvent.getParameter('projectID');

              if (this.debug) {
                console.log(parms2);
              }

              this.userInterfaceService.loadPage('Projects', 'search', parms2);

            } else {
              this.modalDialogService.showAlert('Something went wrong...', 'Warning!').subscribe(
                () => {
                  // this.userInterfaceService.loadPage('InBox');
                }
              );
            }

          } catch (ex) {
            const msg2 = 'AudioInBox.approve.callback()\n';
            this.userInterfaceService.alertUserToException(ex, msg2);
          }
        }
      );

    } catch (ex) {
      const msg = 'AudioInBox.approve()\n';
      this.userInterfaceService.alertUserToException(ex, msg);
    }
  }

  cancel(): void {
    if (this.user.isPublicOnly) {
      this.userInterfaceService.loadPage('home');
    } else {
      super.cancel();
    }
  }

  get openForBusiness(): boolean {

    let openForBusiness = false;

    const now = new Date();

    const utcHours = now.getUTCHours();

    // US DST starts mid-March, but UK/GMT DST starts end of March... ... approximately.
    const timeZoneOffset = ((now.getMonth() === 2) && (now.getDate() >= 15) ? 4 : 5);

    const hoursMyTime = utcHours - timeZoneOffset;

    const beginWorkingHours = 8;
    const endWorkingHours = 20;

    // console.log('utcHours:', utcHours, 'timeZoneOffset:', timeZoneOffset, 'beginWorkingHours:', beginWorkingHours, 'hoursMyTime:', hoursMyTime, 'endWorkingHours:', endWorkingHours);

    openForBusiness = (
        (beginWorkingHours < hoursMyTime)
        &&
        (hoursMyTime < endWorkingHours)
    );

    return openForBusiness;

  }
}
