import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { WField } from 'src/app/client-core/data/field.model';
import { ResourceComponent } from 'src/app/client-core/data/resource/resource.component';

@Component({
  selector: 'wackadoo-customer',
  templateUrl: './customer.component.html',
})
export class CustomerComponent extends ResourceComponent implements OnInit, OnDestroy {

  @Input() customerInfoTitle = null;
  @Input() customerChangedSubject: Subject<WField> = null;
  @Input() additionalMessage: string = null;

  ngOnInit(): void {
    super.ngOnInit();

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

}
