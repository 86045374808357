import { Component, OnInit } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';
import { Globals } from 'src/app/client-core/services/global.service';

@Component({
  selector: 'wackadoo-project-detail-content',
  templateUrl: './project-detail-content.component.html',
})
export class ProjectDetailContentComponent extends DetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'ProjectDetailContentComponent';

  domain = '';

  ngOnInit(): void {
    super.ngOnInit();
    this.domain = Globals.domain;
  }

}
