<div class="" [ngClass]="{ 'paddingWholeEm d-flex justify-content-around align-items-center' : (screenType != 'phone') }" *ngIf="resource">
  <div class="card">
    <div class="card-header">
      Client Info
    </div>
    <div class="card-body">
      <table>
        <tr>
          <td class="bold smallish top">
            Name
          </td>
          <td>
            <div class="d-flex flex-row">
              <div>
                <wackadoo-field [f]="resource.clientFirstName" [mode]="mode" [size]="12"></wackadoo-field>
              </div>
              <div [ngClass]="{ 'marginLeftHalfEm' : (screenType !== 'phone') }">
                <wackadoo-field [f]="resource.clientLastName" [mode]="mode" [size]="12"></wackadoo-field>
              </div>
            </div>
            <div>
              <wackadoo-field [f]="resource.clientCompany" [mode]="mode" [size]="32"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Address
          </td>
          <td>
            <div>
              <wackadoo-field [f]="resource.clientAddress" [mode]="mode" [size]="32"></wackadoo-field>
            </div>
            <div>
              <wackadoo-field [f]="resource.clientCity" [mode]="mode" [size]="32"></wackadoo-field>
            </div>
            <div class="d-flex flex-row">
              <div>
                <wackadoo-field [f]="resource.clientState" [mode]="mode" [size]="2"></wackadoo-field>
              </div>
              <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.clientPostalCode" title="zipcode" [mode]="mode" [size]="5"></wackadoo-field>
              </div>
              <div class="marginLeftHalfEm">
                <wackadoo-field [f]="resource.clientCountry" [mode]="mode" [size]="2"></wackadoo-field>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
              Contact Info
          </td>
          <td>
            <div>
              <wackadoo-field [f]="resource.clientEmail" [mode]="mode" [size]="32"></wackadoo-field>
            </div>
            <div>
              <wackadoo-field [f]="resource.clientPhone" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Download Password * 
          </td>
          <td>
            <div class="max-width-20-em">
              <wackadoo-field-password-show-me [f]="resource.clientPassword" [mode]="mode" [size]="32"></wackadoo-field-password-show-me>
            </div>
            <div>
              <wackadoo-field [f]="resource.clientPasswordHint" [mode]="mode" [size]="32"></wackadoo-field>
            </div>
            <div class="italic small">
              * You'll need this password later, to download the completed files, so be sure to include a cryptic-but-clear-to-you hint. 
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Client Note
          </td>
          <td>
            <div class="fullWidth">
              <wackadoo-field-string [f]="resource.clientNote" [mode]="mode" [forceWrap]="true"></wackadoo-field-string>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
              Un-subscribed?
          </td>
          <td>
            <div>
              <wackadoo-field [f]="resource.unsubscribed" [mode]="mode"></wackadoo-field>
            </div>
            <div>
              <wackadoo-field [f]="resource.unsubscribedDate" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <div>
        Negotiated Prices
      </div>
      <div class="small italics">
        (Note: Project-level negotiated prices take precedence over Client-level negotiated prices, if both are present.)
      </div>
    </div>
    <div class="card-body">
      <table>
        <tr>
          <td class="bold smallish top">
            Mix
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.clientNegotiatedPriceMix" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Mix & Vocal Editing
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.clientNegotiatedPriceMixAndVocalEdit" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            Mix & Master
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.clientNegotiatedPriceMixAndMaster" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
        <tr>
          <td class="bold smallish top">
            The Package
          </td>
          <td>
            <div class="max-width-5-em">
              <wackadoo-field [f]="resource.clientNegotiatedPricePackage" [mode]="mode"></wackadoo-field>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
