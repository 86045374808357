import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-clients',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class AudioClientsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'AudioClientDetailContentComponent';
    // this.detailButtonBarComponentClassName = 'AudioClientDetailButtonBarComponent';
    super.ngOnInit();
  }

}
