<div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
  
  <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
		<div class="card">
			<div class="card-header">
				Hello!
			</div>
			<div class="card-body">
        <div class="marginHalfEm">
          <p>
            And congratulations! You have found the most transparent <i>(to say nothing of 'self-deprecating'...)</i> website on the internet!
          </p>
          <p>
            According to the <a href="http://www.oxforddictionaries.com/definition/english/wackadoo" target="_blank">Oxford Dictionary</a>, 
            a "wackadoo" is "An eccentric or fanatical person". I think that fits, because I've got a couple wackadoo ideas stuck in my head.
          </p>
          <div class="marginBottomWholeEm">
            First, business should be simple, straight-forward and cooperative, so here are my goals - which are correspondingly direct:
            <ul>
              <li>
                To make your music sound as great - to <i>feel</i> as great - as it did in your head when you were first imagining it.
              </li>
              <li>
                To give you the confidence that it will sound good no matter where you play it - on ear buds, in a car, on the PA system 
                of a Welsh football stadium, or right next to your favorite artists on that streaming playlist.
              </li>
              <li>
                To earn your trust before I ask for your hard-earned money, because you don't know who I am, or how good a job I'm going to do for you. Yet.
              </li>
            </ul>
          </div>
          <p>
            Second, I will mix and master your music to a professional standard.
          </p>
          <p>
            Frankly put, audio work is NOT what I spent my professional life doing, but it has been a serious avocation since I was 
            young, thin, good-looking and had hair. Now, as I make this pivot in life, I am offering to help you complete your 
            music using the skills I have grown in working on my own music; skills that I am <i>very actively</i> continuing to develop.
          </p>
          <p>
            I am nowhere near done learning - you never stop learning - but I am pursuing a training program that will take 
            me into, through, and beyond the next year - hence the big yellow watermark on the website.
            <i>(BTW: In case you're wondering, this web site is fully operational. It's <b>me</b> that's the work in progress...)</i>
            Once I have reached a sufficient level of proficiency, I will change some of the language on this website, take down the 
            watermark, and Photoshop in a little hair. <i>(OK, maybe not that last bit...)</i> But I will be adding examples to my 
            (at this point...), rather sparesly populated <span class="link" wackadooPageLoad pageName="Portfolio">Portfolio</span> 
            page, as I build up my clientele.
          </p>
          <p>
            I'm fully confident that you'll like what you hear. So much so, that to earn your trust, I'm offering your first mix &/or master 
            for free, and including 3 revision cycles in the base price of every song.
          </p>
          <p>
            So go ahead. Take a flier on me. You've got nothing to lose. See if I achieve my goals. If you think so, then please hire 
            me for your next project. But if you don't, then you still got a bunch of usable work out of me for free. 
          </p>
          <p>
            I hope to hear from you.
          </p>
          <div>Thanks,</div>
          <div>Steve Kuenzli</div>
          <div><a href="mailto:steve@wackadoo.audio">steve@wackadoo.audio</a></div>
        </div>
			</div>
		</div>
  </div>
  <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
    <div class="polaroid rotate_right marginTop4em">
      <img src="./assets/app-images/video frame.png" alt="Portrait Of An Old Man">
      <div class="italic">
        My ugly mug...
      </div>
    </div>
    <div class="polaroid rotate_left">
      <img src="./assets/app-images/cutaway (7).jpg" alt="5 string bass">
      <div class="italic">
        My primary instrument, when I play out...
      </div>
    </div>
  </div>
</div>
