<div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">

  <div class="card">
    <div class="card-header">
      Refund Policy
    </div>
    <div class="card-body">
      <div class="marginHalfEm">
        <p>
          We do not issue refunds.
        </p>
      </div>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      Billing Policy
    </div>
    <div class="card-body">
      <p>
        Payment is due upon receipt of the "Completion Notice" email, which includes a link to your Invoice/ShoppingCart page.
      </p>
    </div>
  </div>

</div>

<div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">

  <div class="card">
    <div class="card-header">
      Confidentiality/Privacy Policy
    </div>
    <div class="card-body">
      <p>
        We don't give your information to anyone - and that includes our information about you. 
        We don't sell mailing lists. We don't give it to our partners except as necessary for 
        operational transactions - such as, when paying us via credit card.
      </p>
      <p>
        Within the limitations of U.S. law, we will not give access to your data to anyone but you. Period.
      </p>
      <p>
        Note that our stated policy is to comply with legal requests made by U.S. law enforcement officials.
      </p>
    </div>
  </div>

  <div class="card">
    <div class="card-header">
      Data Retention Policy
    </div>
    <div class="card-body">
      <p>
        We perform automated backups of our systems daily to protect against hardware, software, operational or environmental failures.
      </p>
      <p>
        Part of that data is an archive of all work done, which we maintain both for our own records, and in the event 
        that you, as the client, request additional services on the project at some future date.
      </p>
    </div>
  </div>

</div>
