import { Component, OnInit, OnDestroy, Input, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { BannerComponent } from 'src/app/client-core/ui/banner/banner.component';

@Component({
  selector: 'wackadoo-audio-banner',
  templateUrl: './audio-banner.component.html',
})
export class AudioBannerComponent extends BannerComponent implements OnInit, OnDestroy {

  @Input() parentElementRef: ElementRef = null;

  currentPage: string;
  currentPageSubscription: Subscription;

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('audio banner init', this.appName, this.previousApp);

    this.currentPageSubscription = this._userInterfaceService.currentPage.subscribe(
      (currentPage: string) => {
        // console.log('audio banner: ' + JSON.stringify(currentPage));
        this.currentPage = currentPage;
      }
    );

  }

  ngOnDestroy(): void {
    if (this.currentPageSubscription) {
      this.currentPageSubscription.unsubscribe();
    }
    super.ngOnDestroy();
  }

  loadPage(pageName: string): void {
    this.clickBannerMenu();
    this._userInterfaceService.loadPage(pageName);
  }

}
