<div class="" [ngClass]="{'smallish' : screenType === 'phone' }" *ngIf="resource" >
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone')  }">
    <div class="card" [ngClass]="{ 'maxWidthViewPort45' : (screenType !== 'phone') ,'smallish' : (screenType !== 'tablet') }">
      <div class="card-header">
        Client Info
      </div>
      <div class="card-body">
        <div [ngClass]="{ 'd-flex justify-content-between' : (screenType !== 'phone') }">
          <div class="marginBottomHalfEm">
            <table>
              <tr *ngIf="!resource.existingClient.value || user.isAdminOrAbove">
                <td class="bold smallish top right">
                  Name
                </td>
                <td>
                  <div class="d-flex flex-row">
                    <div>
                      <wackadoo-field [f]="resource.clientFirstName" [mode]="mode" [size]="12"></wackadoo-field>
                    </div>
                    <div [ngClass]="{ 'marginLeftHalfEm' : (screenType !== 'phone') }">
                      <wackadoo-field [f]="resource.clientLastName" [mode]="mode" [size]="12"></wackadoo-field>
                    </div>
                  </div>
                  <div>
                    <wackadoo-field [f]="resource.clientCompany" [mode]="mode" [size]="32"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right">
                    <ng-container *ngIf="!resource.existingClient.value || user.isAdminOrAbove; else newLabel;">
                      Contact Info
                    </ng-container>
                    <ng-template #newLabel>
                      EMail Address
                    </ng-template>
                </td>
                <td>
                  <div>
                    <wackadoo-field [f]="resource.clientEmail" [mode]="mode" [size]="32"></wackadoo-field>
                  </div>
                  <div *ngIf="!resource.existingClient.value || user.isAdminOrAbove">
                    <wackadoo-field [f]="resource.clientPhone" [mode]="mode" title="mobile, WhatsApp"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr *ngIf="!resource.existingClient.value || user.isAdminOrAbove">
                <td class="bold smallish top right">
                  Download Password * 
                </td>
                <td>
                  <div class="max-width-20-em">
                    <wackadoo-field-password-show-me [f]="resource.clientPassword" [mode]="mode" [size]="32"></wackadoo-field-password-show-me>
                  </div>
                  <div>
                    <wackadoo-field [f]="resource.clientPasswordHint" [mode]="mode" [size]="32"></wackadoo-field>
                  </div>
                  <div class="italic small max-width-25-em">
                    * You'll need this password later, to download the completed files, so be sure to include a cryptic-but-clear-to-you hint. 
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right">
                  <div class="">
                    Returning Client?
                  </div>
                </td>
                <td>
                  <div>
                    <wackadoo-field [f]="resource.existingClient" [mode]="mode" [onChange]="existingClientChangedSubject"></wackadoo-field>
                  </div>
                  <div class="max-width-25-em" *ngIf="resource.existingClient.value && !user.isAdminOrAbove">
                    Great to see you again! I'll tie this new project to your existing contact information and download password.
                    <span class="italic">
                      (And if I can't find a matching email address, I'll email this address requesting the missing information.)
                    </span>
                  </div>
                  <div class="d-flex flex-row align-items-center" *ngIf="user.isAdminOrAbove" >
                    Existing Client ID: <wackadoo-field [f]="resource.existingClientID" [mode]="mode"></wackadoo-field>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card" [ngClass]="{ 'maxWidthViewPort45' : (screenType !== 'phone') , 'smallish' : (screenType !== 'tablet') }">
      <div class="card-header">
        Project Info
      </div>
      <div class="card-body">
        <div [ngClass]="{ 'd-flex justify-content-between' : (screenType !== 'phone') }">
          <div class="marginBottomHalfEm">
            <table>
              <tr>
                <td class="bold smallish top right">
                  Artist
                </td>
                <td>
                  <div>
                      <wackadoo-field [f]="resource.artistName" [mode]="mode" [size]="32"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right">
                  Project Name
                </td>
                <td>
                  <div>
                      <wackadoo-field [f]="resource.projectName" [mode]="mode" [size]="32" [onChange]="projectNameChangedSubject"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right">
                  Number of Songs
                </td>
                <td class="d-flex flexrow">
                  <div>
                      <wackadoo-field [f]="resource.numberOfSongs" [mode]="mode" [onChange]="songCountChangedSubject"></wackadoo-field>
                  </div>
                  <div class="marginLeftWholeEm">
                      <wackadoo-field [f]="resource.projectType" [mode]="mode" [onChange]="songCountChangedSubject"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right nowrap">
                  Requested Due Date
                </td>
                <td>
                  <div>
                      <wackadoo-field [f]="resource.dueDate" [mode]="mode"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right">
                  Download Link
                </td>
                <td>
                  <div class="d-flex justify-content-between">
                    <div>
                      <div>
                          <wackadoo-field [f]="resource.downloadLink" [mode]="mode" [size]="32"></wackadoo-field>
                      </div>
                      <div class="italic small nowrap marginBottomHalfEm">
                        (Zip File Guides: 
                        <span class="link" (click)="zipFileForMixModal()">
                          Mix
                        </span>
                        |
                        <span class="link" (click)="zipFileForMasterModal()">
                          Master
                        </span>
                        |
                        <span class="link" (click)="zipFileForStemMasterModal()">
                          Stem Master
                        </span>
                        )
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top right">
                  Project Note
                </td>
                <td>
                  <wackadoo-field-string [f]="resource.projectNote" [mode]="mode" [size]="48" [forceWrap]="true" title="Is there anything else I should know about the project?"></wackadoo-field-string>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngFor="let numsInRow of songOrder | column:(isSingleColumn ? 1 : 2);"> 
    <div class="marginTopWholeEm d-flex justify-content-around">
      <ng-container *ngFor="let n of numsInRow;">
        <div class="card border round-corners" [ngClass]="{ 'minWidthViewPort45 maxWidthViewPort45' : ((screenType === 'fullscreen') && !userInterfaceService.isSmallFullScreen()) }" *ngIf="n <= resource.numberOfSongs.value">
          <div class="card-header">
            <div [ngClass]="{ 'd-flex justify-content-between align-items-center' : (screenType !== 'phone') }">
              <div class="nowrap">
                #{{n}} <wackadoo-field [f]="resource.getField('songName' + n)" [mode]="mode" [size]="(screenType !== 'phone' ? 20 : 45)"></wackadoo-field>
              </div>
              <div class="d-flex flex-row align-items-center" [ngClass]="{ 'smallish marginTopQuarterEm floatRight' : (screenType === 'phone') }">
                <div class="smallish nowrap marginLeftHalfEm d-flex flex-row align-items-center">
                  <div class="max-width-3-em">
                    <wackadoo-field [f]="resource.getField('bpm' + n)" [mode]="mode"></wackadoo-field>
                  </div>
                  <div class="marginLeftHalfEm marginRightHalfEm">
                    bpm
                  </div>
                </div>
                <div class="smallish nowrap marginLeftHalfEm d-flex flex-row align-items-center">
                  <div class="marginLeftHalfEm marginRightHalfEm right">
                    key
                  </div>
                  <div>
                    <wackadoo-field [f]="resource.getField('mainKey' + n)" [mode]="mode" [size]="3"></wackadoo-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" [ngClass]="{ 'small' : (screenType === 'phone') }">
            <div class="">
              <div [ngClass]="{'d-flex justify-content-between' : screenType !== 'phone' }">
                <div class="bold smallish">
                  Requested Services
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-between align-items-center marginRightHalfEm nowrap">
                      Mix <wackadoo-field [f]="resource.getField('mix' + n)" [mode]="mode" [onChange]="mixChangedSubject"></wackadoo-field>
                  </div>
                  <div class="d-flex justify-content-between align-items-center marginLeftHalfEm marginRightHalfEm nowrap" *ngIf="resource.getField('mix' + n).value">
                      VocalEdit <wackadoo-field [f]="resource.getField('vocalEditing' + n)" [mode]="mode"></wackadoo-field>
                  </div>
                  <div class="d-flex justify-content-between align-items-center marginLeftHalfEm marginRightHalfEm nowrap">
                      Master <wackadoo-field [f]="resource.getField('master' + n)" [mode]="mode"></wackadoo-field>
                  </div>
                  <div class="d-flex justify-content-between align-items-center marginLeftHalfEm nowrap">
                      Complex <wackadoo-field [f]="resource.getField('complex' + n)" [mode]="mode"></wackadoo-field>
                  </div>
                </div>
              </div>
              <div class="marginTopHalfEm" [ngClass]="{'d-flex justify-content-between' : screenType !== 'phone' }">
                <div class="bold smallish">
                  Reference Track
                </div>
                <div>
                  <div class="d-flex justify-content-between">
                    <div class="">
                      <wackadoo-field [f]="resource.getField('referenceSong' + n)" [mode]="mode" [size]="32"></wackadoo-field>
                    </div>
                    <div class="marginLeftWholeEm">
                      <wackadoo-field [f]="resource.getField('referenceArtist' + n)" [mode]="mode" [size]="32"></wackadoo-field>
                    </div>
                  </div>
                  <div class="small italic marginLeftHalfEm" *ngIf="(n === 1)">
                    (This is a song that has a similar style, feel, or general sound that you want.)
                  </div>
                  <div class="small italic marginLeftHalfEm" *ngIf="(n === 2) && (resource.numberOfSongs.value > 1)">
                    (One reference track is required. More would be better.)
                  </div>
                </div>
              </div>
              <div class="marginTopHalfEm" *ngIf="(screenType !== 'phone')">
                <wackadoo-field-string [f]="resource.getField('songNote' + n)" [mode]="mode" [size]="48" [forceWrap]="true" title="Is there anything else I should know about this song?"></wackadoo-field-string>
              </div>
            </div>
            <div class="marginTopHalfEm" *ngIf="(screenType === 'phone')">
              <wackadoo-field-string [f]="resource.getField('songNote' + n)" [mode]="mode" [size]="48" [forceWrap]="true" title="Is there anything else I should know about this song?"></wackadoo-field-string>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
