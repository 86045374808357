<div *ngIf="shoppingCart">
  <div *ngIf="clientToken && (shoppingCartItems.length > 0)">
<!--
    <div class="small">
      <div class="underline">Debug info...</div>
      <div>clientToken: {{clientToken}}</div>
      <div>donor info: {{shoppingCart.firstName.value}} {{shoppingCart.lastName.value}} ({{shoppingCart.vendorCustomerID.value}})</div>
      <div>shoppingCartID: {{shoppingCart.shoppingCartID.value}} ({{shoppingCartItems.length}} items)</div>
      <div>amount: ${{amount}}</div>
    </div>
-->
    <div class="card">
      <div class="card-header lightblue-bg d-flex justify-content-between">
        <div>
          Credit Card Information
        </div>
        <div>
          <span class="italic red" *ngIf="testPaymentMode">(Test)</span>
        </div>
        <div>
          <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span>
        </div>
      </div>
      <div class="card-body" [ngClass]="{ 'widthViewPort25' : (screenType !== 'phone') }">
        <div class="marginBottomHalfEm smallish">
          <a class="link" (click)="toggle()" [attr.aria-expanded]="!isCollapsed">
            Click here to learn about how we protect your credit card...
          </a>
        </div>
        <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
          <div class="well lightblue-bg marginWholeEm smallish">
            The credit card input section below is a "drop-in" form from our credit card service provider 
            <a href="https://www.stripe.com/" target="_blank"><span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span></a> 
            that acts like a tunnel directly
            to their servers. We do not look at or store the credit card information that you enter below. 
            It goes straight to them. (This mechanism is a key part of our PCI compliance.)
          </div>
        </div>
        <div class="marginTopHalfEm" id="payment-form">
          <!-- The credit card form will over-write this... -->
        </div>
        <div class="d-flex justify-content-around marginTopWholeEm marginBottomHalfEm ">
          <button type="button"
                  class="btn btn-secondary smallish green"
                  (click)="reserveInventoryAndSubmitPayment()" 
                  title="Submit the credit card payment"
                  [disabled]="!readyToReceivePayments || !isLive"
          >
            Submit Payment
          </button>
          <button type="button" 
                  class="btn btn-secondary smallish red" 
                  (click)="cancel()"
          >
            Cancel
          </button>
        </div>
        <div class="small italic center marginHalfEm center" *ngIf="testPaymentMode">
          Please note that, since <b>{{accountName}}</b> is currently in <b>Test Mode</b>, 
          no payments will actually be charged against the credit card you enter.
        </div>
      </div>
    </div>
  </div>
  <div class="paddingHalfEm" *ngIf="!clientToken">
    <div class="center">
      <button type="button" 
              class="btn btn-secondary shadow darkgreen-bg white large" 
              (click)="checkout()"
              [disabled]="!isLive || !readyToReceivePayments || (shoppingCartItems.length === 0)"
      >
        <span class="fas fa-shopping-cart"></span> Checkout
        <span class="italic unbold red" *ngIf="testPaymentMode">
          (Test)
        </span>
      </button>
      <div class="small italic center marginHalfEm" [ngClass]="{ 'max-width-25-emX' : (screenType !== 'phone') }"
          *ngIf="isLive && readyToReceivePayments && testPaymentMode"
      >
        Please note that <b>{{accountName}}</b> is currently in <b>Test Mode</b>.
        <span *ngIf="!useStripePaymentElementsToCheckout">
          This means that no payments will actually be charged against the credit card you are about to enter.
        </span>
      </div>
    </div>
  </div>
  <div class="small italic center marginWholeEm paddingWholeEm" *ngIf="!isLive || !readyToReceivePayments">
    <div class="" *ngIf="!isLive">
        We are not quite ready to accept payments yet.
    </div>
    <div class="" *ngIf="!readyToReceivePayments">
      <div *ngIf="!doneCheckingAccountStatus">
        Checking {{accountName}}&apos;s {{vendorName}} account status...
      </div>
      <div *ngIf="doneCheckingAccountStatus">
        {{accountName}} is not yet configured to receive payments via {{vendorName}}.
      </div>
    </div>
    <div class="">
      We apologize for the inconvenience. Please try again later.
    </div>
  </div>
  <!-- The ID of this empty div is used to scroll the form into view once the Stripe form has loaded -->
  <div id="bottomOfForm"></div>
</div>
