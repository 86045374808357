<div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    <div [ngClass]="{ 'maxWidthViewPort30' : (screenType !== 'phone') }" *ngIf="(screenType !== 'phone')">
      <div class="polaroid marginTop4em">
        <img class="maxHeightViewPort65" src="./assets/app-images/speaker cone.jpg" alt="Speaker cone">
        <div class="italic">
          Stuff you can listen to...
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Did I mention this was rather <i>sparsely</i> populated at this point?
        </div>
        <div class="card-body">
          <p>
            Here's the page where I'll stick a handful of examples - once I have permission to use them.
          </p>
          <p class="italic">
            (Maybe stick a couple of my own most recent ones here? I don't think so. Likely to drive prospective clients away with my singing...)  
          </p>
          <p>
            Or maybe I'll stick these examples somewhere else, like on the Home page. Hmmm...
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
