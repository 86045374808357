import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { ModalDialogService } from 'src/app/client-core/services/modal-dialog.service';
import { StringComponent } from '../string/string.component';

@Component({
  selector: 'wackadoo-field-password-show-me',
  templateUrl: './password-show-me.component.html',
})
export class PasswordShowMeComponent extends StringComponent implements OnInit, OnDestroy {

  passwordIsHidden = true;

  constructor(
    renderer: Renderer2,
    modalDialogService: ModalDialogService,
  ) {
    super(renderer, modalDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  togglePasswordMask(e: any): void {
    this.passwordIsHidden = !this.passwordIsHidden;
  }

}
