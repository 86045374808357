import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';

@Component({
  selector: 'wackadoo-audio-about-us',
  templateUrl: './audio-about-us.component.html',
})
export class AudioAboutUsComponent extends PageComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
