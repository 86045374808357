<div *ngIf="priceList">
  <div class="card">
    <div class="card-header">
      {{priceListTitle}}
    </div>
    <div class="card-body">
      <div *ngFor="let pli of priceList;">
        <div class="border-bottom">
          <div class="left d-flex justify-content-between" *ngIf="pli">
            <div class="" [ngClass]="{ 'd-flex flex-row' : (screenType !== 'phone') }" >
              <div class=""  [ngClass]="{ 'large marginHalfEm' : (screenType !== 'phone') ,  'marginQuarterEm' : (screenType === 'phone') }" >
                <wackadoo-field [f]="pli.itemName" mode="read"></wackadoo-field>
              </div>
              <div class="" [ngClass]="{ 'marginHalfEm marginTopWholeEm' : (screenType !== 'phone') ,  'marginBottomHalfEm' : (screenType === 'phone') }" >
                <ul class="smallish">
                  <li *ngIf="pli.description1.isPopulated">
                    <wackadoo-field [f]="pli.description1" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description2.isPopulated">
                    <wackadoo-field [f]="pli.description2" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description3.isPopulated">
                    <wackadoo-field [f]="pli.description3" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description4.isPopulated">
                    <wackadoo-field [f]="pli.description4" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description5.isPopulated">
                    <wackadoo-field [f]="pli.description5" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description6.isPopulated">
                    <wackadoo-field [f]="pli.description6" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description7.isPopulated">
                    <wackadoo-field [f]="pli.description7" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description8.isPopulated">
                    <wackadoo-field [f]="pli.description8" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description9.isPopulated">
                    <wackadoo-field [f]="pli.description9" mode="read"></wackadoo-field>
                  </li>
                  <li *ngIf="pli.description10.isPopulated">
                    <wackadoo-field [f]="pli.description10" mode="read"></wackadoo-field>
                  </li>
                </ul>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div class="marginHalfEm">
                <wackadoo-field [f]="pli.itemPrice" mode="read"></wackadoo-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
