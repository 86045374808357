import { Component } from '@angular/core';
import { InvoiceComponent } from './invoice.component';

@Component({
  selector: 'wackadoo-thank-you-invoice',
  templateUrl: './thank-you-invoice.component.html',
})
export class ThankYouInvoiceComponent extends InvoiceComponent {

  public goToDownloadPage(e: any): void {
    this.userInterfaceService.setPageState('Download', 'projectID', this.project.projectID.value);
    this.userInterfaceService.loadPage('Download');
  }
}
