<div class="marginHalfEm" [ngClass]="{'small' :  isSmallFullscreenOrTablet, 'smallish' :  screenType === 'phone'}" *ngIf="project">
  <div class="marginHalfEm d-flex flex-row align-items-center">
    <div class="marginHalfEm round-corners">
      <img class="max-height-6-em" src="{{ accountLogoURL }}" alt="" />
    </div>
    <div class="" [ngClass]="{'marginHalfEm' : (screenType !== 'phone')}">
      <div class="bold" [ngClass]="{'extraLarge' :  screenType !== 'phone', 'large' :  screenType === 'phone'}">
        Downloads for <span class="italics">{{project.projectName.value}}</span>
      </div>
    </div>
  </div>
  <div class="" [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    <div class="marginBottomHalfEm large" [ngClass]="{'maxWidthViewPort45' : (screenType != 'phone'), 'small' :  screenType === 'phone'}" >
      <div class="marginBottomHalfEm">
        My goal is for you to <span class="bold italic green">absolutely love</span> what you hear. 
      </div>
      <div class="marginBottomHalfEm">
        To that end, every song I do includes as many revision cycles as necessary as part of the base-line pricing.
      </div>
      <div class="marginBottomHalfEm">
        If you do not <span class="bold italic green">absolutely love</span> what you hear, please reach out  
        to me via DM, WhatsApp, text, phone, email, whatever. If you prefer, you can come back to this page later to click the 
        convenient <span class="bold italic green">Needs Work!</span> button, which enables once you have 
        downloaded the current revision of the song.
      </div>
      <div class="marginBottomHalfEm">
        The more specific you are about what needs to be addressed the better. Stuff like this, for example, is great:
        <ul>
          <li>
            @1:42 - can't hear the tambourine
          </li>
          <li>
            @2:15 - BGVs are too quiet
          </li>
        </ul>
      </div>
      <div class="marginBottomHalfEm">
        I will do my absolute best to make sure you get what you want.
      </div>
      <div class="marginBottomHalfEm small italic">
        (A little house-keeping: I keep copies of each revision off-line for archival purposes, 
        but files are generally cleared from the online database 24 hours after you download them.)
      </div>
    </div>
    <div class="card" [ngClass]="{ 'maxWidthViewPort45' : (screenType !== 'phone') }" >
      <div class="card-header">
        Client Information
      </div>
      <div class="card-body">
        <div class="" [ngClass]="{ 'd-flex justify-content-between' : (screenType === 'fullscreen') && !userInterfaceService.isSmallFullScreen() }">
          <div class="marginBottomHalfEm">
            <table>
              <tr>
                <td class="bold smallish top">
                  Name
                </td>
                <td>
                  <div class="d-flex flex-row">
                    <div>
                      <wackadoo-field [f]="project.clientFirstName" mode="read" [size]="12"></wackadoo-field>
                    </div>
                    <div class="" [ngClass]="{ 'marginLeftHalfEm' : (screenType === 'fullscreen') }">
                      <wackadoo-field [f]="project.clientLastName" mode="read" [size]="12"></wackadoo-field>
                    </div>
                  </div>
                  <div>
                    <wackadoo-field [f]="project.clientCompany" mode="read" [size]="32"></wackadoo-field>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top">
                  Address
                </td>
                <td>
                  <div>
                    <wackadoo-field [f]="project.clientAddress" mode="read" [size]="32"></wackadoo-field>
                  </div>
                  <div>
                    <wackadoo-field [f]="project.clientCity" mode="read" [size]="32"></wackadoo-field>
                  </div>
                  <div class="d-flex flex-row">
                    <div>
                      <wackadoo-field [f]="project.clientState" mode="read" [size]="2"></wackadoo-field>
                    </div>
                    <div class="marginLeftHalfEm">
                      <wackadoo-field [f]="project.clientPostalCode" title="zipcode" mode="read" [size]="5"></wackadoo-field>
                    </div>
                    <div class="marginLeftHalfEm">
                      <wackadoo-field [f]="project.clientCountry" mode="read" [size]="2"></wackadoo-field>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="bold smallish top">
                    Contact Info
                </td>
                <td>
                  <div>
                    <wackadoo-field [f]="project.clientEmail" mode="read" [size]="32"></wackadoo-field>
                  </div>
                  <div>
                    <wackadoo-field [f]="project.clientPhone" mode="read"></wackadoo-field>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" [ngClass]="{ 'fullWidth d-flex justify-content-around' : (screenType !== 'phone') }" *ngIf="!inputCPWOK">
    <div class="card">
      <div class="card-header">
        Please enter your password...
      </div>
      <div class="card-body">
        <div class="d-flex flex-row marginWholeEm">
          <div class="bold marginRightHalfEm">
            Password
          </div>
          <div>
            <div>
              <wackadoo-field-simple-password [f]="inputCPW" mode="write" [onChange]="inputCPWChangedSubject"></wackadoo-field-simple-password>
            </div>
            <div class="italics small">
              <span class="red bold" *ngIf="!project.clientPasswordHint || project.clientPasswordHint.isNull">(There's no hint. Contact me if you can't remember your password...)</span>
              <span *ngIf="project.clientPasswordHint && !project.clientPasswordHint.isNull">({{project.clientPasswordHint.value}})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" *ngIf="inputCPWOK">
    <div class="marginTopWholeEm d-flex justify-content-around">
      <div class="card" [ngClass]="{ 'maxWidthViewPort65' :  (screenType === 'fullscreen') && !isSmallFullscreenOrTablet , 'maxWidthViewPort80' :  isSmallFullscreenOrTablet}" >
        <div class="card-header d-flex flex-row align-items-center">
          <div class=" flex-grow-1">
            <span class="fas fa-music"></span> {{project.projectName.value}}
          </div>
          <div class="marginLeftWholeEm marginRightHalfEm d-flex justify-content-between align-items-center" *ngIf="false">
            <div>
              <button type="button" class="smallish btn btn-secondary" [ngClass]="{ 'bold green' : (wavCount > 0) }" (click)="exportProject('wavFile')" [disabled]="(wavCount === 0)">
                <span class="fas fa-file-audio"></span> WAV Zip ({{wavCount}})
              </button>
            </div>
            <div class="marginLeftHalfEm">
              <button type="button" class="smallish btn btn-secondary" [ngClass]="{ 'bold green' : (mp3Count > 0) }" (click)="exportProject('mp3File')" [disabled]="(mp3Count === 0)">
                <span class="fas fa-file-audio"></span> MP3 Zip ({{mp3Count}})
              </button>
            </div>
          </div>
        </div>
        <div class="card-body">
          <ng-container *ngIf=" (songs.length < 6) || (screenType === 'phone') ; else showColumns">
            <div *ngFor="let s of songs; let idx = index">
              <div class="marginHalfEm d-flex justify-content-between align-items-center">
                <div class="flex-grow-1 right">
                  <div>
                    <wackadoo-field [f]="s.songName" mode="read"></wackadoo-field>
                  </div>
                  <div class="nowrap tiny" *ngIf="!s.wavFileDownloadDate.isNull">
                    Downloaded <wackadoo-field [f]="s.wavFileDownloadDate" mode="read"></wackadoo-field>
                  </div>
                </div>
                <div class="center marginLeftQuarterEm">
                  <button type="button" class="nowrap smallish btn btn-secondary" [ngClass]="{ 'bold green' : !s.wavFile.isNull }" (click)="downloadSong(s, 'wavFile')" [disabled]="s.wavFile.isNull">
                    <span class="fas fa-file-audio"></span> WAV
                  </button>
                </div>
                <div class="center marginLeftQuarterEm">
                  <button type="button" class="nowrap smallish btn btn-secondary" [ngClass]="{ 'bold green' : !s.mp3File.isNull }" (click)="downloadSong(s, 'mp3File')" [disabled]="s.mp3File.isNull">
                    <span class="fas fa-file-audio"></span> MP3
                  </button>
                  <div class="tiny" *ngIf="!s.mp3FileDownloadDate.isNull">
                    Downloaded
                  </div>
                  <div class="tiny">
                    <wackadoo-field [f]="s.mp3FileDownloadDate" mode="read"></wackadoo-field>
                  </div>
                </div>
                <div class="marginLeftQuarterEm" > 
                  <button type="button" class="nowrap btn btn-secondary smallish italic {{(s.wavFileDownloadDate.isNull ? 'black' : 'bold green') }}" (click)="requestRevision(s)" [disabled]="s.wavFileDownloadDate.isNull" >
                    Needs Work!
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #showColumns>
            <div class="row marginHalfEm" *ngFor="let sRow of songs | column:((songs.length > 10) && (screenType === 'fullscreen') && !isSmallFullscreenOrTablet ? 3 : 2); let h = index;"> 
              <ng-container *ngFor="let s of sRow;">
                <div class="d-flex justify-content-between align-items-center {{((songs.length > 10) && (screenType === 'fullscreen') && !isSmallFullscreenOrTablet ? 'col-sm-4' : 'col-sm-6')}}">
                  <div class="flex-grow-1 right">
                    <div>
                      <wackadoo-field [f]="s.songName" mode="read"></wackadoo-field>
                    </div>
                    <div class="nowrap tiny" *ngIf="!s.wavFileDownloadDate.isNull">
                      Downloaded <wackadoo-field [f]="s.wavFileDownloadDate" mode="read"></wackadoo-field>
                    </div>
                  </div>
                  <div class="center marginLeftQuarterEm">
                    <button type="button" class="nowrap smallish btn btn-secondary" [ngClass]="{ 'bold green' : !s.wavFile.isNull }" (click)="downloadSong(s, 'wavFile')" [disabled]="s.wavFile.isNull">
                      <span class="fas fa-file-audio"></span> WAV
                    </button>
                  </div>
                  <div class="center marginLeftQuarterEm">
                    <button type="button" class="nowrap smallish btn btn-secondary" [ngClass]="{ 'bold green' : !s.mp3File.isNull }" (click)="downloadSong(s, 'mp3File')" [disabled]="s.mp3File.isNull">
                      <span class="fas fa-file-audio"></span> MP3
                    </button>
                    <div class="tiny" *ngIf="!s.mp3FileDownloadDate.isNull">
                      Downloaded
                    </div>
                    <div class="tiny">
                      <wackadoo-field [f]="s.mp3FileDownloadDate" mode="read"></wackadoo-field>
                    </div>
                  </div>
                  <div class="marginLeftQuarterEm" > 
                    <button type="button" class="nowrap btn btn-secondary smallish italic {{(s.wavFileDownloadDate.isNull ? 'black' : 'bold green') }}" (click)="requestRevision(s)" [disabled]="s.wavFileDownloadDate.isNull" >
                      Needs Work!
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
