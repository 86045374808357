import { Component, OnInit } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';

@Component({
  selector: 'wackadoo-audio-policies',
  templateUrl: './audio-policies.component.html',
})
export class AudioPoliciesComponent extends PageComponent implements OnInit {

  ngOnInit(): void {
    super.ngOnInit();
  }

}
