<div class="marginHalfEm" [ngClass]="{'small' :  screenType === 'tablet', 'smallish' :  screenType === 'phone'}" *ngIf="shoppingCart && project">
  <div class="" [ngClass]="{'d-flex justify-content-between' :  (screenType !== 'phone')}">
    <div class="marginHalfEm d-flex flex-row align-items-center">
      <div class="marginHalfEm round-corners">
        <img class="max-height-6-em" src="{{ accountLogoURL }}" alt="" />
      </div>
      <div class="" [ngClass]="{'marginHalfEm' : (screenType !== 'phone')}">
        <div class="bold" [ngClass]="{'extraLarge' :  screenType !== 'phone', 'large' :  screenType === 'phone'}">
          Thank you for trusting me with your music!
        </div>
        <div class="italic" [ngClass]="{'large' :  screenType !== 'phone', '' :  screenType === 'phone'}">
            It was an honor to work on your {{project.projectType.value}}, <span class="italics">{{project.projectName.value}}</span>.
        </div>
      </div>
    </div>
  </div>
  <div class="marginLeftWholeEm marginBottomHalfEm" [ngClass]="{'small' :  screenType === 'phone'}">
			As creative a person myself, please know how much it means to me on a personal 
			level that you have asked me to help you sound your absolute best.
  </div>
  <div class="" [ngClass]="{'d-flex justify-content-around' :  screenType !== 'phone'}">
    <div class="card">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div>
          <span class="fas fa-shopping-cart"></span>
          Your ShoppingCart{{shoppingCartItems.length === 0 ? '...' : ''}}
        </div>
        <!-- a little debug hack... -->
        <div class="unbold small" *ngIf="user && (user.accountID === 2)">
            {{shoppingCartID}} - {{shoppingCartItems.length}}
        </div>
      </div>
      <div class="card-body">
        <div class="paddingHalfEm italic" *ngIf="shoppingCartItems.length === 0">
          ...is empty.
        </div>
        <div *ngFor="let sci of shoppingCartItems ; let idx = index">
          <div class="paddingTopQuarterEm paddingBottomQuarterEm border-bottom" *ngIf="sci.packageID.isNull">
            <div class="d-flex">
              <div class="d-flex flex-grow-1 justify-content-between">
                <div>
                  <wackadoo-field [f]="sci.itemName" mode="read"></wackadoo-field>
                </div>
                <div class="italics">
                  <wackadoo-field [f]="sci.description" mode="read"></wackadoo-field>
                </div>
              </div>
              <div class="marginLeftHalfEm min-width-3-em right">
                <div>
                  <wackadoo-field [f]="sci.itemExtendedPrice" mode="read"></wackadoo-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between marginHalfEm">
          <div class="italic smallish">
          </div>
          <div class="d-flex justify-content-between bold">
            <div class="marginRightHalfEm right">
              Total:
            </div>
            <div>
              <wackadoo-field [f]="total.itemExtendedPrice" mode="read"></wackadoo-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-around align-items-center flex-column" [ngClass]="{ 'maxWidthViewPort30' : (screenType === 'fullscreen')  }">
      <div>
        <wackadoo-customer
              [resource]="shoppingCart" 
              mode="read" 
              [customerInfoTitle]="customerInfoTitle"
              [additionalMessage]="additionalCustomerMessage"
        ></wackadoo-customer>
      </div>
      <div>
        <div>
          <button type="button" title="Download Files" class="btn btn-secondary" (click)="goToDownloadPage()"><span class="fas fa-file-export"></span> Download Files</button>
        </div>
      </div>
    </div>
  </div>
</div>
