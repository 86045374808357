import { Component } from '@angular/core';
import { PageComponent } from 'src/app/client-core/pages/page/page.component';

@Component({
  selector: 'wackadoo-audio-services',
  templateUrl: './audio-services.component.html',
})
export class AudioServicesComponent extends PageComponent {

}
