import { Component, OnInit } from '@angular/core';
import { RepositoryPageComponent } from 'src/app/client-core/pages/repository-page/repository-page.component';

@Component({
  selector: 'wackadoo-songs',
  templateUrl: '../../../../../../src/app/client-core/pages/repository-page/repository-page.component.html',
})
export class SongsComponent extends RepositoryPageComponent implements OnInit {

  ngOnInit(): void {
    this.detailContentComponentClassName = 'SongDetailContentComponent';
    // this.detailButtonBarComponentClassName = 'SongDetailButtonBarComponent';
    super.ngOnInit();
  }

}
