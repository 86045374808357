import { Component, OnInit, Input, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { EventServerService } from '../../services/event-server.service';
import { UserInterfaceService } from '../../services/user-interface.service';
import { WResource } from '../../data/resource.model';
import { ModalDialogService } from '../../services/modal-dialog.service';
import { WEvent } from '../../data/event.model';
import { Globals } from '../../services/global.service';
import { UtilityLibService } from '../../services/utility-lib.service';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { ScreenType } from '../../services/screen-type.enum';
import { User } from '../../data/user.model';

@Component({
  selector: 'wackadoo-stripe-customer-payment',
  templateUrl: './stripe-customer-payment.component.html',
})
export class StripeCustomerPaymentComponent implements OnInit, OnDestroy {

  @Input() shoppingCart: WResource = null;
  @Input() shoppingCartItems: WResource [] = [];
  @Input() pageElementRef: ElementRef = null;
  @Input() screenType = null;
  @Input() user: User = null;
  @Input() showChrome = true;
  @Input() isLive = false;

  @Output() disableInputs = new EventEmitter<boolean>();

  // account stuff...

  domain: string = null;
  appName: string = null;
  accountName: string = null;

  // credit card payment stuff...

  vendorName: string = null;
  readyToReceivePayments = false;
  doneCheckingAccountStatus = false;

  testPaymentMode = true;

  // default "thank you for donating" page for callback(s)...
  thankYouPage = 'ThankYou';

  amount = 0.0;

  clientToken: string = null;

  stripe: any = null;

  // this allows us to choose between Stripe Checkout (re-direct URL) or Stripe Payment Elements (stay on our page)
  useStripePaymentElementsToCheckout = false;

  // These are populated by Stripe API calls
  stripeElements: any = null;

  isCollapsed = true;

  constructor(
    public eventServerService: EventServerService,
    public userInterfaceService: UserInterfaceService,
    public modalDialogService: ModalDialogService,
    public utilityLibService: UtilityLibService,
    public elementRef: ElementRef,
  ) {
    // NOP
  }

  ngOnInit(): void {

    this.domain = Globals.domain;
    this.appName = Globals.thisApplication;
    this.accountName = this.user.accountName;

    this.clientToken = null;
    this.stripeElements = null;

    this.getVendorName();
    this.initializeCCPVClientAPI();
    this.verifyReadyForPayments();
    this.lookupTestPaymentMode();

    this.calculate();
  }

  ngOnDestroy(): void {
    this.clientToken = null;
    this.stripeElements = null;
  }

  lookupTestPaymentMode(): void {
    const eventHandler = 'CreditCardPaymentVendor';
    const action = 'getTestPaymentMode';
    const parms: any = {};
    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        try {

          // console.log('lookupTestPaymentMode()', event);

          if (event) {
            // event.status is OK, ERROR, 'ACCESS DENIED'
            if (event.status !== 'OK') {
              throw new Error(event.status + ' / ' + event.message);
            }
            // console.log('lookupTestPaymentMode()', (typeof event.parameters.testPaymentMode), event.parameters.testPaymentMode);
            this.testPaymentMode = event.parameters.testPaymentMode;
          }
        } catch (ex) {
          console.log('lookupTestPaymentMode() - error', event);
          this.modalDialogService.showAlert(ex.message, 'Error: lookupTestPaymentMode');
        }
      }
    );
  }

  initializeCCPVClientAPI(): void {

    if (!this.stripe) {

      const eventHandler = 'CreditCardPaymentVendor';
      const action = 'getClientCredentials';
      const parms: any = {};

      this.modalDialogService.showPleaseWait('Loading <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span> API...', true);

      this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
        (event: WEvent) => {

          // console.log('initializeStripeAPI()', event);

          try {

            if (event) {

              // event.status is OK, ERROR, 'ACCESS DENIED'

              if (event.status !== 'OK') {
                throw new Error(event.status + ' / ' + event.message);
              }

              const publicApiKey = event.parameters.publicApiKey;

              // console.log('initializeStripeAPI()', 'publicApiKey', publicApiKey);

              const stripePromise: Promise<Stripe> = loadStripe(publicApiKey);

              stripePromise
                .then((stripeAPI) => {
                  this.modalDialogService.showPleaseWait(false, true);
                  // console.log('stripePromise.then()', stripeAPI);

                  this.stripe = stripeAPI;

                  this.useStripePaymentElementsToCheckout = true;
                })
                .catch((error) => {
                  this.modalDialogService.showPleaseWait(false, true);

                  console.log('stripePromise.catch()', error, event);

                  let msg = 'An error occurred while attempting to load the <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span> API for in-page ShoppingCart checkout.';
                  msg += '\nInstead, you will be re-directed to (and returned from...) their web site for checkout.';

                  this.modalDialogService.showAlert(msg, 'Please Note');
                })
              ;

            } else {
              this.modalDialogService.showPleaseWait(false, true);
            }

          } catch (ex) {
            this.modalDialogService.showPleaseWait(false, true);

            console.log('initializeStripe() - error', event);
            this.modalDialogService.showAlert(ex.message, 'Error: initializeStripe');
          }
        }
      );

    }
  }

  calculate(): void {

    this.amount = 0.0;

    if (this.shoppingCartItems) {
      for (const sci of this.shoppingCartItems) {
        const expectedEP = sci.itemPrice.value * sci.itemQuantity.value;
        if (sci.itemExtendedPrice.value !== expectedEP) {
          let msg = 'We apologize for the inconvenience, but there was a problem with the extended price for this item: ' + sci.itemName.value;
          msg += '\n\n';
          msg += '' + sci.itemPrice.value + ' * ' + sci.itemQuantity.value + ' equals ' + expectedEP + ', which does NOT equal ' + sci.itemExtendedPrice.value;
          this.modalDialogService.showAlert(msg, 'Error');
        }
        // and keep the total up-to-date, remembering to NOT add in amounts for things in packages...
        if (sci.packageID.isNull) {
          this.amount += sci.itemExtendedPrice.value;
        }
      }
    }
    // console.log('calculate()', this.shoppingCartItems.length + ' items, costing a total of $' + this.amount, this.shoppingCartItems);

  }

  checkout(): void {
    this.calculate();

    // checks for null OR empty string values in required ShoppingCart fields...
    let badField = this.shoppingCart.identifyUnPopulatedRequiredField();

    if (!badField) {
      // checks for null OR empty string values in required ShoppingCartItem fields...
      for (const sci of this.shoppingCartItems) {
        badField = sci.identifyUnPopulatedRequiredField();
        if (badField) {
          break;
        }
      }
    }

    // console.log('checkoutOnThisPage()', this.shoppingCart, badField);
    // console.log('checkoutOnThisPage()', this.shoppingCartItems, this.amount);

    if (badField) {
      this.modalDialogService.showAlert('Please fill this in: ' + badField.name, 'Missing A Required Field').subscribe(
        () => {
          this.userInterfaceService.focusOnField(this.pageElementRef, badField);
        }
      );
    } else if (this.shoppingCartItems.length === 0) {
      this.modalDialogService.showAlert('...you do have to have something in your ShoppingCart before you can checkout.', 'Sorry ' + this.shoppingCart.firstName.value + ', but...');
    } else if (this.amount === 0.0) {
      this.modalDialogService.showAlert('...nothing in your ShoppingCart actually costs money.', 'Sorry ' + this.shoppingCart.firstName.value + ', but...');
    } else {

      // 1. disable user inputs

      this.disableInputs.emit(true);

      this.shoppingCart.markAllFieldsAsChanged();
      this.eventServerService.saveResource(this.shoppingCart).subscribe(
        (event: WEvent) => {
          if (event.status !== 'OK') {
            this.modalDialogService.showAlert('We apologize for the inconvenience, but there was an un-expected error while saving your shopping cart details prior to the payment.\n\nPlease double-check that your customer information and shopping cart content is correct, and try again.', 'Warning...');
          } else {
            this.shoppingCart.markAllFieldsAsUnChanged();

            // depending upon which style of checkout we want to use...

            if (this.useStripePaymentElementsToCheckout) {

              // 2(a) - we kick off the Payment Element checkout process
              //        (i.e. generate a clientToken, which in turn, shows the credit card form...)

              this.getClientTokenForPayment();

            } else {

              // 2(b) - we kick off the Checkout Session process (i.e. the re-directed to Stripe process...)

              this.reserveInventoryAndReDirectToCCPVForCheckout();

            }
          }
        }
      );


    }

  }

  //////////////////////////////////////////////////////////////
  // credit card payment vendor methods...
  //////////////////////////////////////////////////////////////

  getVendorName(): void {
    const eventHandler = 'CreditCardPaymentVendor';
    const action = 'getVendorName';
    const parms: any = {};

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        try {

          // console.log('getVendorName()', event);

          if (event) {
            if (event.status !== 'OK') {
              throw new Error(event.status + ' / ' + event.message);
            }
            this.vendorName = event.getParameter('vendorName');
          }
        } catch (ex) {
          console.log(event);
          this.modalDialogService.showAlert(ex.message, 'Error: getVendorName');
        }
      }
    );
  }

  verifyReadyForPayments(): void {
    const eventHandler = 'CreditCardPaymentVendor';
    const action = 'verifyReadyForPayments';
    const parms: any = {};

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {
        try {
          if (event) {

            // console.log('verifyReadyForPayments()', event);

            // event.status is OK, ERROR, 'ACCESS DENIED'

            if (event.status === 'ACCESS DENIED') {
              throw new Error(event.status + ' / ' + event.message);
            }

            // OK means yes, ERROR means no
            this.readyToReceivePayments = (event.status === 'OK');
          }
        } catch (ex) {
          console.log(event);
          this.modalDialogService.showAlert(ex.message, 'Error: verifyReadyForPayments');
        }
        this.doneCheckingAccountStatus = true;
      }
    );
  }

  reserveInventoryAndReDirectToCCPVForCheckout(): void {

    let msg = 'You are about to be re-directed to <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span>';
    msg += ' to make a credit card payment for the items in your shopping cart.';
    msg += ' After they process your payment, you will be returned to this page.';
    msg += '\n\nContinue?';

    const title = 'Please Be Aware...';

    this.modalDialogService.showConfirm(msg, title).subscribe(
      (flag: boolean) => {
        if (flag) {

          // reserve any fixed inventory items (as necessary...)

          this.eventServerService.fireEvent('ShoppingCarts', 'reserve', this.shoppingCart.keyField.asParm).subscribe(
            (re: WEvent) => {
              try {
                this.modalDialogService.showPleaseWait(false);

                if (!re) {
                  throw new Error('Null response trying to reserve inventory...');
                }

                if (re.status !== 'OK') {
                  throw new Error('Error reserving inventory: ' + re.message);
                }

                this.modalDialogService.showPleaseWait('Re-directing you to <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span> to check out...');

                // generate the Checkout re-direct URL

                const parms: any = this.shoppingCart.keyField.asParm;
                parms.thankYouPage = this.thankYouPage + (this.showChrome ? '' : '?hideChrome=true');
                parms.cancelPage = this.userInterfaceService.currentPage.getValue() + (this.showChrome ? '' : '?hideChrome=true');

                this.eventServerService.fireEvent('CreditCardPaymentVendor', 'getCheckoutUrl', parms).subscribe(
                  (event: WEvent) => {
                    try {

                      // console.log('getCheckoutUrl()', event);

                      if (event) {
                        if (event.status !== 'OK') {
                          throw new Error(event.message);
                        }
                        this.utilityLibService.deleteCookie('shoppingCartID');

                        // now fire the actual re-direct...

                        const redirectUrl = event.parameters.redirectUrl;
                        window.location.href = redirectUrl;

                      } else {
                        throw new Error('You should not be reading this, because that button was supposed to re-direct you to ' + this.vendorName + ' for checkout.');
                      }
                    } catch (ex) {
                      console.log(event);
                      this.userInterfaceService.logMessage('Re-direct error while checking out with shoppingCartID (' + this.shoppingCart.keyField.value + ') : ' + event.message);
                      this.modalDialogService.showAlert(ex.message, 'Error');
                    }
                  }
                );

              } catch (ex) {
                const msg2 = 'Unable to reserve inventory for this request. Please try again...';
                this.userInterfaceService.alertUserToException(ex, msg2);
                this.disableInputs.emit(false);
              }
            }
          );
        }
      }
    );

  }

  getClientTokenForPayment(): void {

    const eventHandler = 'CreditCardPaymentVendor';
    const action = 'getClientTokenForPayment';
    const parms: any = this.shoppingCart.keyField.asParm;

    this.modalDialogService.showPleaseWait('Generating <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span> payment form...', true);

    this.eventServerService.fireEvent(eventHandler, action, parms).subscribe(
      (event: WEvent) => {

        try {

          // console.log('getClientTokenForPayment()', event);

          if (event) {

            // event.status is OK, ERROR, 'ACCESS DENIED'

            if (event.status !== 'OK') {
              throw new Error(event.status + ' / ' + event.message);
            }

            this.clientToken = event.parameters.clientToken;

            this.populateThePaymentElement();

          } else {
            this.modalDialogService.showPleaseWait(false);
          }

        } catch (ex) {
          this.modalDialogService.showPleaseWait(false);

          console.log('getClientTokenForPayment() - error', event, ex);
          this.modalDialogService.showAlert(ex.message, 'Error: getClientTokenForPayment');
        }
        this.doneCheckingAccountStatus = true;
      }
    );
  }

  populateThePaymentElement(): void {

    this.stripeElements = null;

    if (this.stripe && this.clientToken) {

      const options = {

        clientSecret: this.clientToken,

        // Fully customizable with appearance API.
        appearance: {
          theme: 'stripe',
          labels: 'floating',

          variables: {
            fontSizeBase: ((this.screenType === ScreenType.fullscreen) ? '14px' : '12px'),
            spacingUnit: '2px',

            // The spacing between rows in the grid used for the Element layout.
            spacingGridRow: '0.5em',
            // The spacing between columns in the grid used for the Element layout.
            spacingGridColumn: '0.5em'
          }
        }
      };

      // console.log('populateThePaymentElement()', options);

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
      this.stripeElements = this.stripe.elements(options);

      // Create and mount the Payment Element
      const paymentElement = this.stripeElements.create('payment');

      // when the form is ready (i.e. it has been loaded from Stripe...)
      paymentElement.on('ready', () => {
        // console.log('paymentElement.ready() fired, creditCard form is ready');

        // clear the spinner
        this.modalDialogService.showPleaseWait(false);

        // scroll the entire form into view...
        window.setTimeout(
          () => {
            const el = this.elementRef.nativeElement.querySelector('#bottomOfForm');
            if (el) {
              // console.log('scrolling now...');
              el.scrollIntoView(false);  // (false mean "scoll to bottom"...)
            }
          }
          , 300
        );
      });

      // The page started re-rendering when we got the clientToken, so at this point, we are in an un-certain UX state.
      // This waits until we HAVE the '#payment-form' element prior to "mounting" the Stripe Payment Element.
      this.mountPaymentFormAfterPageHasReRendered(paymentElement);
    }

  }

  mountPaymentFormAfterPageHasReRendered(paymentElement: any): void {
    // console.log('mountPaymentFormAfterPageHasReRendered()', new Date().toUTCString());
    const paymentForm: HTMLElement = this.elementRef.nativeElement.querySelector('#payment-form');
    if (paymentForm) {
      // console.log('mountPaymentFormAfterPageHasReRendered()', new Date().toUTCString(), 'FIRED', paymentForm, paymentElement);
      paymentElement.mount('#payment-form');
    } else {
      window.setTimeout(
        () => {
          this.mountPaymentFormAfterPageHasReRendered(paymentElement);
        }
        , 100
      );
    }
  }

  reserveInventoryAndSubmitPayment(): void {

    // reserve any fixed inventory items (as necessary...)

    this.eventServerService.fireEvent('ShoppingCarts', 'reserve', this.shoppingCart.keyField.asParm).subscribe(
      (re: WEvent) => {
        try {
          this.modalDialogService.showPleaseWait(false);

          if (!re) {
            throw new Error('Null response trying to reserve inventory...');
          }

          if (re.status !== 'OK') {
            throw new Error('Error reserving inventory: ' + re.message);
          }

          // now we submit the actual payment

          const thankYouPageURL = window.location.origin + '/' + this.thankYouPage + '?shoppingCartID=' + this.shoppingCart.keyField.value + (this.showChrome ? '' : '&hideChrome=true');

          // this.stripeElements is the Stripe 'Elements' instance that was used to create the Payment Element

          const paymentOptions: any = {
              elements: this.stripeElements,
              confirmParams: {
                return_url: thankYouPageURL,
              },
          };

          const {error} = this.stripe.confirmPayment(paymentOptions);

          if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            console.log('submitPayment()', 'Stripe error', error);
            this.modalDialogService.showAlert(error.message, 'Error');
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.

            // that said, payment could be declined WITHOUT an error...

            this.modalDialogService.showPleaseWait('Checking payment status...', true);

            window.setTimeout(
              () => {
                this.stripe.retrievePaymentIntent(this.clientToken)
                  .then((result: any) => {
                    this.modalDialogService.showPleaseWait(false);

                    let msg = null;

                    if (result.paymentIntent) {

                      // console.log('submitPayment()', 'payment intent', result.paymentIntent);

                      switch (result.paymentIntent.status) {
                        case 'succeeded':
                          // NOP
                          // Stripe is about to fire a re-direct...
                          break;
                        case 'processing':
                        case 'requires_payment_method':
                          // This has happened on Google Pay, even though we requested card-only.
                          // We have no way of telling if the request was originally Google Pay or a standard credit card!
                          msg = 'Stripe failed to validate your credit card.\nPlease check your details, and try again.';
                          break;
                        case 'failed':
                          // If we get here, the payment has already failed!
                          msg = 'Your payment has failed. Please try again.';
                          break;
                        default:
                          msg = 'Unknown payment status: ' + result.paymentIntent.status;
                          break;
                      }

                    } else {
                      msg = 'Unexpected error. Unable to determine payment status.';
                      this.userInterfaceService.logMessage(msg + ' (read: missing PaymentIntent.)\n' + result, 'WARNING');
                    }

                    if (msg) {
                      msg += '\n\nWhen you click Cancel, you will be returned to your populated shopping cart.';

                      this.modalDialogService.showAlert(msg, 'Warning');
                    }
                  })
                  .catch((pmtStatusError: any) => {
                    this.modalDialogService.showPleaseWait(false, true);
                    console.log('payment status error', pmtStatusError);
                    const msg = 'An error occurred while attempting to retrieve payment status information.';
                    this.modalDialogService.showAlert(msg, 'Please Note');
                  });
              }
              , 7500
            );

          }
        } catch (ex) {
          const msg2 = 'Unable to reserve inventory for this request. Please try again...';
          this.userInterfaceService.alertUserToException(ex, msg2);
          this.disableInputs.emit(false);
        }
      }
    );

  }

  cancel(): void {
    this.clientToken = null;
    this.stripeElements = null;
    this.disableInputs.emit(false);
  }

  toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

}
