import { Component, OnInit } from '@angular/core';
import { DetailContentComponent } from 'src/app/client-core/pages/repository-page/detail-content/detail-content.component';

@Component({
  selector: 'wackadoo-song-detail-content',
  templateUrl: './song-detail-content.component.html',
})
export class SongDetailContentComponent extends DetailContentComponent implements OnInit {

  // this is here so that the repository-page can find it when loading dynamic content properly in an AOT build
  static componentNameUsedForDynamicContentInAOT = 'SongDetailContentComponent';

  ngOnInit(): void {
    super.ngOnInit();

    // this.userInterfaceService.setPageState(this.eventHandler, 'debugResourceSave', true);

  }

}
