import { Component, OnInit, OnDestroy } from '@angular/core';
import { FieldMode } from 'src/app/client-core/data/field/field-mode.model';
import { Subscription } from 'rxjs';
import { UserInterfaceService } from 'src/app/client-core/services/user-interface.service';
import { ScreenType } from 'src/app/client-core/services/screen-type.enum';

@Component({
  selector: 'wackadoo-audio-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  mode = FieldMode.read;
  fieldModeSubscription: Subscription;

  screenType = null;
  screenTypeSubscription: Subscription;


  constructor(
    public userInterfaceService: UserInterfaceService,
  ) {
  }

  ngOnInit(): void {
    this.fieldModeSubscription = this.userInterfaceService.resourceDetailPageDisplayMode.subscribe(
      (fieldMode: FieldMode) => {
        // console.log('changing fieldMode: ' + fieldMode);
        this.mode = fieldMode;
      }
    );
    this.screenTypeSubscription = this.userInterfaceService.screenType.subscribe(
      (screenType: ScreenType) => {
        // console.log('changing screenType: ' + screenType);
        this.screenType = screenType;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.fieldModeSubscription) {
      this.fieldModeSubscription.unsubscribe();
    }
    if (this.screenTypeSubscription) {
      this.screenTypeSubscription.unsubscribe();
    }
  }

}
