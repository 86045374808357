<div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Getting started...
        </div>
        <div class="card-body">
          <p>
            First, let me be clear: Submitting a project does NOT commit you to pay anything. 
          </p>
          <p>
            All it does is trigger a phone or Zoom call for me to discuss your project 
            with you before I start work. That way, I can make sure that I understand 
            your vision for the project. If everything sounds good to you, I will ask
            you to give me a verbal go-ahead if you want to proceed.
          </p>
          <p>
            When I'm done with the work, I will send you an email with a link to the 
            Invoice/ShoppingCart page where you will be able to pay me for services 
            rendered.
          </p>
          <p>
            If this is the first time we have worked together, your first song will have no charges 
            for the mix, the master or both - depending upon which combination of those you requested.
            <i>(Please note that you would be charged list price for vocal editing - which is optional - 
              should you choose to add it to your free mix.)</i>
          </p>
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_left">
        <img src="./assets/app-images/upright bridge (8).jpg" alt="bass amplifier">
        <div class="italic">
          Sometimes direct, sometimes through a pre-amp...
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="{ 'd-flex justify-content-around align-items-center' : (screenType !== 'phone') }">
    <div [ngClass]="{ 'widthViewPort30' : (screenType !== 'phone') }">
      <div class="polaroid rotate_right marginTop4em">
        <img src="./assets/app-images/cubase (1).jpg" alt="DAW Screenshot">
        <div class="italic">
          Clearly a work in progress...
        </div>
      </div>
    </div>
    <div [ngClass]="{ 'widthViewPort50' : (screenType !== 'phone') }">
      <div class="card">
        <div class="card-header">
          Making sure you like what you hear...
        </div>
        <div class="card-body">
          <div class="marginBottomWholeEm">
            Once payment is received, you'll be re-directed to the 'Thank You' page with your download link. 
            You will also receive two automated emails. 
            <ul>
              <li>
                The first is your receipt from <span class="fab fa-cc-stripe fa-2x middle stripeBlue"></span>, 
                who I use for taking credit card payments. 
              </li>
              <li>
                The second is from <span class="bold italic green">wackadoo.audio</span>, which includes 
                a copy of your download link for future use.
              </li>
            </ul>
          </div>
          <p>
            The secure download page is where you will need the password you created when you first submitted 
            the project. Once you have downloaded your file(s), if you do not 
            <span class="bold italic green">absolutely love</span> what you hear, simply let me know. 
          </p>
          <p>
            You can do that via text, phone, email - or the convenient <i>"This Sucks!"</i> button on the 
            download page. <i>(Yes, it's really called that - for now, anyway...)</i> At that point, I will 
            get in touch with you to see what you would like changed. (You get up to 3 revision cycles 
            with every song...)
          </p>
          <p>
            When the revision is ready, I'll upload it, and let you know that you should re-visit your download page.
          </p>
          <p>
            If you have any questions, feel free to email me, and I will get in touch.
          </p>
          <p>
            But if you're ready to go, simply click here to <span class="link" wackadooPageLoad pageName="InBox">submit a project</span>.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
